@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-Bold.otf') format('opentype');
    font-weight: bold;
}

@font-face {
    font-family: 'Acherus Grotesque';
    src: url('AcherusGrotesque-Regular.otf') format('opentype');
    font-weight: normal;
}