/* autoprefixer grid: autoplace */
@import "assets/fonts/Acherus/font.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --fontFamilyBase: "Acherus Grotesque", sans-serif;
    --fontFamilySecond: "Acherus Grotesque", sans-serif;

    --invalidColor: #B94545;
    --invalidColorHover: #a23c3c;

    --primaryColor: #004E32;
    --primaryColorHover: #33715B;
    --primaryColorDark: #002F1A;
    --disabledOpacity: 0.3;

    --primary-100: #004E32;
    --primary-90: #1A6047;
    --primary-80: #33715B;
    --primary-70: #4D8370;
    --primary-60: #669584;
    --primary-50: #80A698;
    --primary-40: #99B8AD;
    --primary-30: #B3CAC2;
    --primary-20: #CCDCD6;
    --primary-10: #E6EDEB;

    --common-borders: #CCD5D1;

    --secondary-100: #B99A45;
    --secondary-90: #C0A458;
    --secondary-80: #C7AE6A;
    --secondary-70: #CEB87D;
    --secondary-60: #D5C28F;
    --secondary-50: #DCCCA2;
    --secondary-40: #E3D7B5;
    --secondary-30: #EAE1C7;
    --secondary-20: #F1EBDA;
    --secondary-10: #F8F5EC;

    --text-secondary: #00000099;

    --iconColorLight: #FFFFFF;
    --iconColorMedium: #B99A45;
    --iconColorPrimary: #004E32;
    --iconColorPrimaryDark: #B99A45;
    --iconColorPrimaryDarkHover: #a4883f;
    --iconColorDefault: #4D6D5F;

    --textFieldColorDefault: #4D6D5F;

    --gradientPrimary: linear-gradient(270deg, #092119 0%, #1B4532 100%);
    --gradientSecondary: linear-gradient(90deg, rgba(247, 247, 247, 0.00) 0%, #F7F7F7 100%);
}

html, body {
    font-family: var(--fontFamilyBase);
    min-height: 100%;
    height: 100%;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    min-height: -webkit-fill-available;
}

body.overflow {
    overflow: hidden;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    -webkit-tap-highlight-color: transparent;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilyBase);
    font-weight: 700;
}

h1 {
    font-size: 40px;
    line-height: 48px;
}

h2 {
    font-size: 26px;
    line-height: 36px;
}

h3 {
    font-size: 20px;
    line-height: 28px;
}

h4 {
    font-size: 18px;
    line-height: 25px;
}

h5 {
    font-family: var(--fontFamilyBase);
    font-size: 18px;
    line-height: 20px;
}

h6 {
    font-family: var(--fontFamilyBase);
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

input,
button,
select {
    outline: none;
    font-family: var(--fontFamilyBase);
}

button {
    cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
    font-size: 16px !important;
}


.overflow-popover .MuiBackdrop-root {
    opacity: 0 !important;
}

/*Fix for popover arrow if needed*/
.overflow-popover > .MuiPaper-root {
    overflow: visible;
}

.tutorial-modal {
    z-index: 1200;
}

.native-disable {
    opacity: 0.3;
    cursor: default;
}

.MuiFormLabel-root[data-shrink="true"] {
    font-size: 0.9rem;
}

#ot-sdk-btn-floating {
    display: none;
}


body.fixed-scroll {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.safe-area-view {
    --defaultPaddingBottom: 12px;
    padding-bottom: var(--defaultPaddingBottom);
    /* iOS Safari 11.2, Safari 11 */
    padding-bottom: calc(var(--defaultPaddingBottom) + constant(safe-area-inset-bottom, var(--defaultPaddingBottom)));
    /* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
    padding-bottom: calc(var(--defaultPaddingBottom) + env(safe-area-inset-bottom, var(--defaultPaddingBottom)));
}
